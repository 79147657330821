$g-back: #212529;
$btn-red: #e64759;

html {
  height: 100%;
  font-family: sans-serif;
  font-size:12px;
  line-height: 1.15;
}

body {
  height: 100%;
  background: $g-back;
  color: #fff;
}

a {
  color: #00a8ff !important;
  text-decoration: none !important;
}

.logo{
  width:300px;
}

.logo-sm{
  width:200px;
}

#outer-container {
  height: 100%;
}

.text-center {
  text-align: center !important;
}

#app {
  height: 100%;
  width: 100%;
}
#page-wrap {
  height: 100%;
  width: 100%;
  padding: 3em 1em;
  text-align: center;
  overflow: auto;
  display: table;
  //background:url('/src/app/img/back.jpg');
  //border: dashed 5px gray;
  
}
.form-signin {

  padding: 15px;
  margin: auto;
  display: table-cell;  
  vertical-align: middle;
  //border: dashed 3px green;
}
.my-control{
  width: 330px;  
  display: inline;
  text-align: center;
}

.pagina {
  padding: 0px;
  padding-top: 15px;
  height: 100%;
  //border: dashed 3px green;
}

.menu-icon {
  color:#f8e810;
  font-size: 18px;
}

.tabla {
  font-size: 1em;
}

.pointer {
  cursor: pointer;
}

.btn-red {
  background: $btn-red !important;
  color:white !important;
}

.my-container {
  border:dashed 0px white;
}

.my-row {
  border:dashed 0px lightgray;
}

.my-col {
  border: dashed 3px lightcoral;
}

.my-modal {
  color: black; 
  width: 50%;
  margin:auto;
}

.modal-content {
  background: #FDFEFE;
  color: black;
}

.options-icons a {
  display: inline-block;
  margin: 0.75 em;
  padding: 1.35 em 1.1 em;
  width: 15 em;
  cursor: pointer;
}

.menu-item {
  padding-top: 1em;
  padding-bottom: 1em;
  text-decoration: none;
  text-align: left;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #20232a;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
  color:white !important;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
